import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { AUGER_OFFER_TYPES } from '../Constants/enums'
import Auth from '../Auth/Auth';
import { CIDSContentList } from './ContentPackageList';
import { GetContentPackages } from '../Api/RomaWebApi';
import { ContentPackageDetails } from '../Models/AugerOffers/MarketplaceDetails';

interface Props {
    auth: Auth;
}
  
function ContentPackageBrowser(props: Props) {
    const [cidSubmissions, setCIDSubmissions] = useState<ContentPackageDetails[]>([]);
    const [error, setError] = useState<any>();

    useEffect(() => {
        GetContentPackages(props.auth).then((result: ContentPackageDetails[]) => {
            if (result.length === 0) {
                console.log("No Offers Found");
                setCIDSubmissions([]);
                setError("No offers found");
            } else {
                setCIDSubmissions(result);
            }
        }).catch((error) => {
            console.log("error", error);
            setCIDSubmissions([]);
            setError(error);
        });
    }, [props.auth]);

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <Card className="text-center">
                        <CIDSContentList type={AUGER_OFFER_TYPES.CIDS_OFFER} submissions={cidSubmissions} auth={props.auth} />
                        {error && <p>{error.message}</p>}
                    </Card>
                </div>
            </div>
        </main>
    );
}

export default ContentPackageBrowser;