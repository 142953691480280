import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Auth from '../Auth/Auth'
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { MisPackageDetailsResponse } from '../Models/AugerOffers/MarketplaceDetails';
import { GenericCheckboxForm, TextFormField } from './FormFields/FormField';

interface Props {
    auth: Auth,
    onSave: (marketplacePackage: MarketplacePackage) => void,
    onCancel: () => void,
    show: boolean,
    marketplacePackage: MarketplacePackage,
    marketplacePackageDetails?: MisPackageDetailsResponse
}

export function MarketplaceProductRentals(props: Props) {
    const [marketplacePackageDetails, setMarketplacePackageDetails] = useState<MisPackageDetailsResponse | undefined>(props.marketplacePackageDetails);
    const [enableSaveButton, setEnableSaveButton] = useState(false);

    function processFormInfo(durationInDays?: number, propertyName?: string, propertyValue?: any) { 
        if (!marketplacePackageDetails) {
            return;
        }
    
        if (durationInDays) {
            if (propertyName === "isRental") {
                const rentalInfoPrice = marketplacePackageDetails.rentalInfo?.prices.find(price => price.durationInDays === durationInDays);

                if (marketplacePackageDetails.rentalInfo && rentalInfoPrice && !propertyValue) {
                    marketplacePackageDetails.rentalInfo.prices = marketplacePackageDetails.rentalInfo.prices.filter(price => price.durationInDays !== durationInDays);

                    if (marketplacePackageDetails.rentalInfo.prices.length === 0 && !marketplacePackageDetails.rentalInfo.freeTrial) {
                        marketplacePackageDetails.rentalInfo = undefined;
                    }
                } else if (!rentalInfoPrice && propertyValue) {
                    if (!marketplacePackageDetails.rentalInfo) {
                        marketplacePackageDetails.rentalInfo = { prices: [{ durationInDays: durationInDays, priceInUSD: "" }] };
                    } else {
                        marketplacePackageDetails.rentalInfo.prices.push({durationInDays: durationInDays, priceInUSD: ""});
                    }
                }
            } else if (propertyName === "price") {
                const rentalInfoPrice = marketplacePackageDetails.rentalInfo?.prices.find(price => price.durationInDays === durationInDays);

                if (rentalInfoPrice) {
                    rentalInfoPrice.priceInUSD = propertyValue;
                } else if (marketplacePackageDetails.rentalInfo) {
                    marketplacePackageDetails.rentalInfo.prices.push({ durationInDays: durationInDays, priceInUSD: propertyValue });
                } else {
                    marketplacePackageDetails.rentalInfo = { prices: [{ durationInDays: durationInDays, priceInUSD: propertyValue }] };
                }
            } else if (propertyName === "isFreeTrial") {
                if (propertyValue) {
                    if (!marketplacePackageDetails.rentalInfo?.freeTrial) {
                        if (!marketplacePackageDetails.rentalInfo) {
                            marketplacePackageDetails.rentalInfo = { freeTrial: { durationInDays: durationInDays, version: marketplacePackageDetails.latestFreeTrialVersion ?? 0 }, prices: [] };
                        } else {
                            marketplacePackageDetails.rentalInfo.freeTrial = { durationInDays: durationInDays, version: marketplacePackageDetails.latestFreeTrialVersion ?? 0 };
                        }
                    } else {
                        marketplacePackageDetails.rentalInfo.freeTrial.durationInDays = durationInDays;
                    }

                    if (marketplacePackageDetails.rentalInfo.prices.length > 0) {
                        marketplacePackageDetails.rentalInfo.prices = marketplacePackageDetails.rentalInfo.prices.filter(price => price.durationInDays > durationInDays);
                    }
                } else {
                    if (durationInDays === (marketplacePackageDetails.rentalInfo?.freeTrial?.durationInDays ?? 1)) {
                        if (marketplacePackageDetails.rentalInfo) {
                            marketplacePackageDetails.rentalInfo.freeTrial = undefined;

                            if (marketplacePackageDetails.rentalInfo.prices.length === 0) {
                                marketplacePackageDetails.rentalInfo = undefined;
                            }
                        }
                    }
                }
            }
        } else if (propertyName === "resetFreeTrial" && marketplacePackageDetails.rentalInfo?.freeTrial) {
            marketplacePackageDetails.rentalInfo.freeTrial.version = (marketplacePackageDetails.latestFreeTrialVersion ?? 0) + propertyValue ? 1: 0;
        }

        setMarketplacePackageDetails((previousMarketplacePackageDetails: any) => ({
            ...previousMarketplacePackageDetails,
            rentalInfo: marketplacePackageDetails.rentalInfo
        }));

        props.marketplacePackage.rentalInfo.value = marketplacePackageDetails.rentalInfo;
        
        setEnableSaveButton(true);
    }

    return (
        <Modal show={props.show} onHide={props.onCancel} backdrop={"static"} keyboard={false} centered={true}>
            <Modal.Header style={{display: "flex", justifyContent: "center" }}>
                <Modal.Title>Rentals and Free Trials</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                        <GenericCheckboxForm<number>
                            key={"1DayRental"}
                            context={1}
                            value={marketplacePackageDetails?.rentalInfo?.prices?.find(price => price.durationInDays === 1) !== undefined}
                            propertyName={"isRental"}
                            returnCallback={processFormInfo}
                            isNew={true}
                            inline={true}
                            checkboxFields={[{value: true, name: '1 Day'}]} 
                            disabled={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 1}
                            offerUndo={false} />
                        <GenericCheckboxForm<number>
                            key={"1DayFreeTrial"}
                            context={1}
                            value={!!marketplacePackageDetails?.rentalInfo?.freeTrial}
                            propertyName={"isFreeTrial"}
                            returnCallback={processFormInfo}
                            isNew={true}
                            inline={true}
                            checkboxFields={[{value: true, name: 'Free'}]} 
                            disabled={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 3}
                            offerUndo={false} />
                        <TextFormField<number>
                            key={"1DayPrice"}
                            context={1}
                            value={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 1 ? "0.00" : marketplacePackageDetails?.rentalInfo?.prices?.find(price => price.durationInDays === 1)?.priceInUSD ?? ""}
                            placeholder={"Enter Price"}
                            helpContent={"Price in USD"}
                            propertyName={"price"}
                            returnCallback={processFormInfo}
                            isNew={true}
                            inline={true}
                            disabled={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 1}
                            offerUndo={false} /> 
                </span>
                <span style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                        <GenericCheckboxForm<number>
                            key={"3DayRental"}
                            context={3}
                            value={marketplacePackageDetails?.rentalInfo?.prices?.find(price => price.durationInDays === 3) !== undefined}
                            propertyName={"isRental"}
                            returnCallback={processFormInfo}
                            isNew={true}
                            inline={true}
                            checkboxFields={[{value: true, name: '3 Day'}]} 
                            disabled={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 3}
                            offerUndo={false} />
                        <GenericCheckboxForm<number>
                            key={"3DayFreeTrial"}
                            context={3}
                            value={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 3}
                            propertyName={"isFreeTrial"}
                            returnCallback={processFormInfo}
                            isNew={true}
                            inline={true}
                            visible={false}
                            checkboxFields={[{value: true, name: 'Free'}]} 
                            disabled={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 7}
                            offerUndo={false} />
                        <TextFormField<number>
                            key={"3DayPrice"}
                            context={3}
                            value={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 3 ? "0.00" : marketplacePackageDetails?.rentalInfo?.prices?.find(price => price.durationInDays === 3)?.priceInUSD ?? ""}
                            placeholder={"Enter Price"}
                            helpContent={"Price in USD"}
                            propertyName={"price"}
                            returnCallback={processFormInfo}
                            isNew={true}
                            inline={true}
                            disabled={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 3}
                            offerUndo={false} /> 
                </span>
                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <GenericCheckboxForm<number>
                            key={"7DayRental"}
                            context={7}
                            value={marketplacePackageDetails?.rentalInfo?.prices?.find(price => price.durationInDays === 7) !== undefined}
                            propertyName={"isRental"}
                            returnCallback={processFormInfo}
                            isNew={true}
                            inline={true}
                            checkboxFields={[{value: true, name: '7 Day'}]} 
                            disabled={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 7}
                            offerUndo={false} />
                        <GenericCheckboxForm<number>
                            key={"7DayFreeTrial"}
                            context={7}
                            value={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 7}
                            propertyName={"isFreeTrial"}
                            returnCallback={processFormInfo}
                            isNew={true}
                            inline={true}
                            visible={false}
                            checkboxFields={[{value: true, name: 'Free'}]} 
                            offerUndo={false} />
                        <TextFormField<number>
                            key={"7DayPrice"}
                            context={7}
                            value={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 7 ? "0.00" : marketplacePackageDetails?.rentalInfo?.prices?.find(price => price.durationInDays === 7)?.priceInUSD ?? ""}
                            placeholder={"Enter Price"}
                            helpContent={"Price in USD"}
                            propertyName={"price"}
                            returnCallback={processFormInfo}
                            isNew={true}
                            inline={true}
                            disabled={(marketplacePackageDetails?.rentalInfo?.freeTrial?.durationInDays ?? 0) >= 7}
                            offerUndo={false} /> 
                </span>
                <div style={ { display: "flex", justifyContent: "center", marginTop: 20 } }>
                    <GenericCheckboxForm<number>
                        key={"resetFreeTrial"}
                        value={(marketplacePackageDetails?.rentalInfo?.freeTrial?.version ?? 0) > (marketplacePackageDetails?.latestFreeTrialVersion ?? 0)}
                        propertyName={"resetFreeTrial"}
                        helpContent={"Make this available for all users regardless of whether they have previously used the free trial."}
                        returnCallback={processFormInfo}
                        isNew={true}
                        inline={true}
                        checkboxFields={[{value: true, name: 'Reset Free Trial'}]} 
                        disabled={!marketplacePackageDetails?.rentalInfo?.freeTrial}
                        offerUndo={false} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' disabled={!enableSaveButton} onClick={() => props.onSave(props.marketplacePackage)}>Save</Button>
                <Button variant='secondary' onClick={props.onCancel}>{enableSaveButton ? "Cancel" : "Close"}</Button>
            </Modal.Footer>
        </Modal>
    );
}
