import React, { useEffect, useState } from 'react';
import { Publisher } from '../../Models/Publisher';
import { Alert, Button, Card, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { formStyle } from '../../Constants/styles';
import { createPartnerV2, updatePartnerV2 } from '../../Api/WebApi';
import Auth from '../../Auth/Auth';
import { GenericCheckboxForm, TagFormField, TextFormField } from '../FormFields/FormField';
import { Developer } from '../../Models/Developer';

interface Props {
    auth: Auth;
    publisher: Publisher;
    developers: Developer[];
    isNew: boolean;
    updatePartnerCallback: any;
}

function PublisherModal(props: Props) {
    const [publisher, setPublisher] = useState<Publisher>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        if (props.publisher) {
            setPublisher(props.publisher);
        } else {
            setPublisher(new Publisher());
        }
    }, [props.publisher]);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const saveChanges = () => {
        const createNewPartner = async () => {
            const result: Publisher = await createPartnerV2(props.auth, publisher)
                .catch((e) => {
                    console.log("Error creating partner: ", e);
                    setError(e.response.data)
                });

            if (result) {
                setPublisher(result);
                setError(null);
                props.updatePartnerCallback(result);
                closeModal();
            }
        }

        const updateExistingPartner = async () => {
            const result: Publisher = await updatePartnerV2(props.auth, publisher)
                .catch((e) => {
                    console.log("Error updating partner: ", e);
                    setError(e.response.data)
                });

            if (result) {
                setPublisher(result);
                setError(null);
                props.updatePartnerCallback(result);
                closeModal();
            }
        }

        if (props.isNew) {
            createNewPartner()
        } else {
            updateExistingPartner()
        }
    };

    const updatePartnerProperty = function <V>(publisher?: Publisher, propertyName?: keyof Publisher, value?: V) {
        if (propertyName) {
            setPublisher((prevPartner: any) => ({
                ...prevPartner,
                [propertyName]: value,
            }));
        }
    };

    const getPartnerFormFields = (): JSX.Element[] => {
        let formFields: JSX.Element[] = [];
      
        if (publisher === null || publisher === undefined) {
          return formFields;
        }
    
        formFields.push(<TextFormField<Publisher>
            key={"sellerId"}
            value={(publisher?.sellerId) ? publisher.sellerId : ''}
            title={"Seller Id"}
            placeholder={"SellerId"}
            helpContent={"Partner Seller Id"}
            propertyName={"sellerId"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TextFormField<Publisher>
            key={"publisherId"}
            value={(publisher?.id) ? publisher.id : ''}
            title={"Publisher Id"}
            placeholder={"Publisher Id"}
            helpContent={"Partner Publisher Id"}
            propertyName={"id"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={!props.isNew} // Form Field enabled only when creating a new partner
            offerUndo={false} />);
    
        formFields.push(<TextFormField<Publisher>
            key={"name"}
            value={(publisher?.name) ? publisher.name : ''}
            title={"Name"}
            placeholder={"Name"}
            helpContent={"Partner Name"}
            propertyName={"name"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TagFormField<Publisher>
            key={"developers"}
            context={publisher}
            value={publisher?.developers}
            title={"Developers"}
            placeholder={"Developer Id"}
            helpContent={"Type in the id of a developer, and hit enter."}
            propertyName={"developers"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={false}
            optionsList={props.developers?.map(p => { return { key: p.id, value: p.id } } )}
            offerUndo={false} />);

        formFields.push(<TextFormField<Publisher>
            key={"partnerPriority"}
            value={publisher?.partnerPriority}
            title={"Partner Priority"}
            placeholder={"Partner Priority"}
            helpContent={"Partner Priority"}
            propertyName={"partnerPriority"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TextFormField<Publisher>
            key={"releaseOwner"}
            value={publisher?.releaseOwner}
            title={"Release Owner"}
            placeholder={"Release Owner"}
            helpContent={"Release Owner"}
            propertyName={"releaseOwner"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<GenericCheckboxForm<Publisher>
            key={"isApproved"}
            value={publisher.isApproved}
            title={"Approved"}
            helpContent={"Approved"}
            propertyName={"isApproved"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            checkboxFields={[{value: true, name: 'Approved'}]} 
            offerUndo={false} />);
    
        return formFields;
    };

    return (
        <div className='modal-container'>
            <div className='modal-button'>
                {!props.isNew && <FontAwesomeIcon icon={faPenToSquare} className="icon" onClick={openModal}/>}
                {props.isNew && <Button variant="success" size="sm" onClick={openModal}>CREATE NEW PARTNER</Button>}
            </div>
            <div className="partnerModal">
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{(props.isNew) ? 'Create New Partner' : props.publisher?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card style={formStyle}>
                            {getPartnerFormFields()}
                        </Card>
                        <div>
                            {error && (
                                <Alert variant="danger" onAbort={() => {setError(null)}}>
                                    <h4>Error:</h4>
                                    <p>{error}</p>
                                </Alert>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={saveChanges}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
      );
}

export default PublisherModal;