import React, { useEffect, useState } from 'react';
import { Developer } from '../../Models/Developer';
import { Publisher } from '../../Models/Publisher';
import { UserAccessLevel, User } from "../../Models/User";
import { Alert, Button, Card, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { formStyle } from '../../Constants/styles';
import { createUserV2, updateUserV2 } from '../../Api/WebApi';
import Auth from '../../Auth/Auth';
import { DropDownFormField, GenericCheckboxForm, TagFormField, TextFormField } from '../FormFields/FormField';

interface Props {
    auth: Auth;
    user: User;
    publishers: Publisher[];
    developers: Developer[];
    isNew: boolean;
    updateUserCallback: any;
}

function UserModal(props: Props) {
    const [user, setUser] = useState<User>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<any>();
    
    useEffect(() => {
        if (props.user) {
            setUser(props.user);
        } else {
            setUser(new User());
        }
    }, [props.user]);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
      
    const saveChanges = () => {
        const createNewUser = async () => {
            const result: User = await createUserV2(props.auth, user)
                .catch((e) => {
                    console.log("Error creating user: ", e);
                    setError(e.response.data)
                });

            if (result) {
                setUser(new User());
                setError(null);
                props.updateUserCallback(result);
                closeModal();
            }
        }

        const updateExistingUser = async () => {
            const { user: User } = await updateUserV2(props.auth, user)
                .catch((e) => {
                    console.log("Error updating user: ", e);
                    setError(e.response.data)
                });

            if (user) {
                setUser(user);
                setError(null);
                props.updateUserCallback(user);
                closeModal();
            }
        }

        if (props.isNew) {
            createNewUser()
        } else {
            updateExistingUser()
        }
    };

    const updateUserProperty = function <V>(user?: User, propertyName?: keyof User, value?: V) {
        if (user) {
            if (propertyName === "publishers") {
                user.publishers = {};
                (value as string[]).map(publisher => user.publishers[publisher] = "Contributor" );
            } else if (propertyName === "developers") {
                user.developers = {};
                (value as string[]).map(developer => user.developers[developer] = "Contributor" );
            } else if (propertyName) {
                setUser((prevUser: any) => ({
                    ...prevUser,
                    [propertyName]: value,
                }));
            }
        }
    };

    const getUserFormFields = (): JSX.Element[] => {
        let formFields: JSX.Element[] = [];
      
        if (user === null || user === undefined) {
          return formFields;
        }
    
        formFields.push(<TextFormField<User>
            key={"email"}
            context={user}
            value={(user?.email) ? user.email : ''}
            title={"Email"}
            placeholder={"Email"}
            helpContent={"User Email"}
            propertyName={"email"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TextFormField<User>
            key={"name"}
            context={user}
            value={(user?.name) ? user.name : ''}
            title={"Name"}
            placeholder={"Name"}
            helpContent={"User Name"}
            propertyName={"name"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TextFormField<User>
            key={"gamerTag"}
            context={user}
            value={(user?.gamerTag) ? user.gamerTag : ''}
            title={"GamerTag"}
            placeholder={"GamerTag"}
            helpContent={"User GamerTag"}
            propertyName={"gamerTag"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
        
        formFields.push(<TextFormField<User>
            key={"xuid"}
            context={user}
            value={(user?.xuid) ? user.xuid : ''}
            title={"XUID"}
            placeholder={"XUID"}
            helpContent={"User XUID"}
            propertyName={"xuid"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
        
        formFields.push(<GenericCheckboxForm<User>
            key={"enableBetaFeatures"}
            context={user}
            value={(typeof user.enableBetaFeatures === 'boolean') ? user.enableBetaFeatures : user.enableBetaFeatures === 'true'}
            title={"Enable Beta Features"}
            helpContent={"Enable Beta Features"}
            propertyName={"enableBetaFeatures"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            checkboxFields={[{value: true, name: 'Enable Beta Features'}]} 
            offerUndo={false} />);
    
        formFields.push(<TagFormField<User>
            key={"publishers"}
            context={user}
            value={user?.publishers && Object.keys(user.publishers)}
            title={"Publishers"}
            placeholder={"Publisher Id"}
            helpContent={"Type in the id of a partner, and hit enter."}
            propertyName={"publishers"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            sort={true}
            optionsList={props.publishers?.map(p => { return { key: p.id, value: p.id } } )}
            offerUndo={false} />);

        formFields.push(<TagFormField<User>
            key={"developers"}
            context={user}
            value={user?.developers && Object.keys(user.developers)}
            title={"Developers"}
            placeholder={"Developer Id"}
            helpContent={"Type in the id of a developer, and hit enter."}
            propertyName={"developers"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            sort={true}
            optionsList={props.developers?.map(p => { return { key: p.id, value: p.id } } )}
            offerUndo={false} />);

        const roleTypeOptions = Object.values(UserAccessLevel).map(value => {
            return {
                option: value,
                disabled: false
            };
        });
        formFields.push(<DropDownFormField<User>
            key={"role"}
            context={user}
            value={(user.mcpAccessLevel)}
            title={"Role"}
            isNew={props.isNew}
            helpContent={"User Role"}
            propertyName={"mcpAccessLevel"}
            returnCallback={updateUserProperty}
            disabled={false}
            offerUndo={false}
            optionsList={roleTypeOptions} />);
    
        formFields.push(<GenericCheckboxForm<User>
            key={"approved"}
            context={user}
            title={"Approved"}
            value={user.isApproved}
            helpContent={"Approved"}
            propertyName={"isApproved"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            checkboxFields={[{value: true, name: 'Approved'}]} 
            offerUndo={false} />);
    
        return formFields;
    };

    return (
        <div className='modal-container'>
            <div className='modal-button'>
                {!props.isNew && <FontAwesomeIcon icon={faPenToSquare} className="icon" onClick={openModal}/>}
                {props.isNew && <Button variant="success" size="sm" onClick={openModal}>CREATE NEW USER</Button>}
            </div>
            <div className="userModal">
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{(props.isNew) ? 'Create New User' : props.user?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card style={formStyle} >
                            {getUserFormFields()}
                        </Card>
                        <div>
                            {error && (
                                <Alert variant="danger" onAbort={() => {setError(null)}}>
                                    <h4>Error:</h4>
                                    <p>{error}</p>
                                </Alert>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={saveChanges}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
      );
}

export default UserModal;