import React, { Component } from 'react';
import ReactTable from "../ReactTable";
import AugerOfferFactory from '../Factories/AugerOfferFactory';
import Auth from '../Auth/Auth';
import { ContentPackage } from '../Models/AugerOffers/ContentPackage';
import { MarketplaceItem } from '../Models/AugerOffers/MarketplaceItem';
import { ContentPackageDetails } from '../Models/AugerOffers/MarketplaceDetails';
import { ContentPackageBrowserDetails } from './ContentPackageBrowserDetails';

interface Props {
    auth: Auth;
    submissions: ContentPackageDetails[];
    type: string;
}

interface State {
    augerModel: typeof MarketplaceItem | null | undefined;
    auth: Auth;
    offers?: ContentPackage[] | null;
}

class CIDSContentList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            augerModel: AugerOfferFactory.getModel(props.type),
            auth: props.auth,
            offers: null
        };

        this.subComponent = this.subComponent.bind(this);
    }
    
    componentDidUpdate(prevProps: Props) {

        if (prevProps.submissions !== this.props.submissions) {
            if(this.props.submissions === null){
                this.setState({ offers: null});
            }
            else if(this.props.submissions === undefined){
                this.setState({ offers: undefined});
            }
            else{
                this.setState({ offers: this.props.submissions.map(offer => AugerOfferFactory.build(this.props.type, offer) as ContentPackage) });
            }
        }
    }

    componentDidMount() {

    }

    subComponent (row: any) {
        const contentPackage = this.state.offers?.find((contentPackage) => contentPackage.packageName.value === row.original.packageName);

        if (contentPackage) {
            return <ContentPackageBrowserDetails contentPackage={contentPackage} />
        }
    }

    render() {
        const {
            offers
        } = this.state;

        var tableData:any[] = [];
        var isFirstParty = this.state.auth.isInternal();
        var columns = ContentPackage.getColumnHeaders(isFirstParty);
        var loading = true;
        var noDataText = "No offers found!";

           //loading
        if(offers === null){
            noDataText = "";
        }  //error
        else if (offers === undefined){
            loading = false;
            noDataText = "Error retrieving submissions! Please contact your partner manager!";
        }  //finished
        else{
            loading = false;
            if (offers.length !== 0) {
                tableData = offers.map(function (offer: ContentPackage) {
                    let report = offer.convertToColumnData();
                    return report;
                });
            }
        }

        var lastPageSize = JSON.parse(localStorage.getItem('lastBrowserPageSize') ?? "10");

        var lastFilter:string | null | undefined = localStorage.getItem('lastBrowserFilter');

        if (lastFilter && lastFilter !== 'undefined') {
            lastFilter = JSON.parse(lastFilter);
        } else {
            lastFilter = undefined;
        }

        var lastSorted:string | null | undefined = localStorage.getItem('lastBrowserSort');

        if (lastSorted && lastSorted !== 'undefined') {
            lastSorted = JSON.parse(lastSorted);

            if (lastSorted?.length === 0) {
                lastSorted = undefined;
            }
        } else {
            lastSorted = undefined;
        }

        return (
            <div>
                <em>Tip: Hold shift when sorting to multi-sort!</em>
                <ReactTable
                    onSortedChange={(newSorted: string) => {
                        if (newSorted) {
                            localStorage.setItem('lastBrowserSort', JSON.stringify(newSorted));
                        }
                      }}
                    onFilteredChange={(filtered:boolean) => {
                        if (filtered) {
                            localStorage.setItem('lastBrowserFilter', JSON.stringify(filtered));
                        } else {
                            localStorage.removeItem('lastBrowserFilter');
                        }
                    }}
                    onPageSizeChange={(pageSize:string) => {
                        if (pageSize) {
                            localStorage.setItem('lastBrowserPageSize', pageSize);
                        }
                    }}
                    style={{clear:'right'}}
                    data={tableData}
                    columns={columns}
                    loading={loading}
                    SubComponent={this.subComponent}
                    minRows={0}
                    noDataText = {noDataText}
                    defaultSorted={lastSorted ?? (isFirstParty ? ContentPackage.defaultFirstPartyListSorting : ContentPackage.defaultListSorting)}
                    defaultFiltered={(lastFilter) ? lastFilter : undefined}
                    defaultPageSize={lastPageSize ?? 10}
                    className="-striped -highlight"
                />
            </div>
        )

    }
}

export {
    CIDSContentList,
}